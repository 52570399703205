/*@jsxRuntime classic @jsx React.createElement @jsxFrag React.Fragment*/
import {useMDXComponents as _provideComponents} from "@mdx-js/react";
import React from "react";
import {L} from "@src/style/mdxStyle.js";
import ReactCompareImage from "react-compare-image";
function _createMdxContent(props) {
  const _components = Object.assign({
    h1: "h1",
    a: "a",
    div: "div",
    p: "p",
    h2: "h2",
    h3: "h3"
  }, _provideComponents(), props.components);
  return React.createElement(React.Fragment, null, React.createElement(_components.h1, {
    id: "5-advanced-tricks-with-luminosity-masks",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#5-advanced-tricks-with-luminosity-masks",
    "aria-label": "5 advanced tricks with luminosity masks permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "5 advanced tricks with luminosity masks"), "\n", React.createElement(_components.p, null, "Luminosity masks is a great tool, but like with any tool, there can be many ways how to use it. The basics of how masks in general and luminosity masks work you can find in my ", React.createElement(L, {
    t: "free course",
    h: "https://arcpanel.averin.photo/blog/2020-03-15-luminosity-masking-basics-00-overview/"
  }), ". In this article I want to show 5 advanced tricks with luminosity masks that you probably didn't know about."), "\n", React.createElement(_components.p, null, "You have probably heard that luminosity masks help greatly when blending exposures, controlling details, making natural looking HDR images and so on. But there are many more things you can do with them."), "\n", React.createElement(_components.p, null, "For convenience I will be using ARCPanel to create all masks, but you can also create luminosity masks manually if you want. Manual steps to create masks are described in ", React.createElement(L, {
    t: "this article",
    h: "https://arcpanel.averin.photo/blog/2020-05-07-luminosity-masks/"
  }), "."), "\n", React.createElement(_components.h2, {
    id: "limiting-luminosity-mask",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#limiting-luminosity-mask",
    "aria-label": "limiting luminosity mask permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "Limiting luminosity mask"), "\n", React.createElement(_components.p, null, "Let's start with the most simple technique but, at the same time, the most popular."), "\n", React.createElement(_components.p, null, "You can use the group with the mask to further limit your luminosity masking effect."), "\n", React.createElement(ReactCompareImage, {
    leftImage: props.data.mdx.frontmatter.images[0].publicURL,
    rightImage: props.data.mdx.frontmatter.images[1].publicURL
  }), "\n", React.createElement(_components.p, null, "Very simple but very powerful technique."), "\n", React.createElement(_components.p, null, "You can read more about exact steps on how to do it in ", React.createElement(L, {
    t: "this article",
    h: "https://arcpanel.averin.photo/blog/2020-05-22-limiting-luminosity-masks/"
  }), "."), "\n", React.createElement(_components.h2, {
    id: "remove-sky-spots",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#remove-sky-spots",
    "aria-label": "remove sky spots permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "Remove sky spots"), "\n", React.createElement(_components.p, null, "When shooting woodland photography quite often you get a very nice scene and usually you try to get as little of sky as possible to avoid distractions. Still, you can get some of the sky visible behind the leaves and when it's bright, it can be very distracting to the viewer."), "\n", React.createElement(_components.p, null, "Luckily, there is a way to fix it in post."), "\n", React.createElement(ReactCompareImage, {
    leftImage: props.data.mdx.frontmatter.images[2].publicURL,
    rightImage: props.data.mdx.frontmatter.images[3].publicURL
  }), "\n", React.createElement(_components.p, null, "A detailed description and exact steps to take are described ", React.createElement(L, {
    t: "here",
    h: "https://arcpanel.averin.photo/blog/2020-05-22-remove-sky-with-luminosity-masks/"
  }), "."), "\n", React.createElement(_components.h3, {
    id: "disclaimer",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#disclaimer",
    "aria-label": "disclaimer permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "Disclaimer"), "\n", React.createElement(_components.p, null, "Now we are moving into a more creative part. I can understand that these effects can be controversial and not everybody will be willing to apply them in the images."), "\n", React.createElement(_components.h2, {
    id: "realistic-sun-rays",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#realistic-sun-rays",
    "aria-label": "realistic sun rays permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "Realistic sun rays"), "\n", React.createElement(_components.p, null, "Surely you have seen some of the videos on YouTube that show to add sun rays to the image. Luminosity masks can drastically improve how sun rays look and make them more realistic."), "\n", React.createElement(ReactCompareImage, {
    leftImage: props.data.mdx.frontmatter.images[4].publicURL,
    rightImage: props.data.mdx.frontmatter.images[5].publicURL
  }), "\n", React.createElement(_components.p, null, "You can find more details in  ", React.createElement(L, {
    t: "this article",
    h: "https://arcpanel.averin.photo/blog/2020-05-22-realistic-sun-rays-with-luminosity-masks/"
  }), "."), "\n", React.createElement(_components.h2, {
    id: "painterly-soft-shadows",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#painterly-soft-shadows",
    "aria-label": "painterly soft shadows permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "Painterly soft shadows"), "\n", React.createElement(_components.p, null, "In the images of many famous landscape photographers you can see the effect of soft shadows. Now, there are many ways you can build it."), "\n", React.createElement(ReactCompareImage, {
    leftImage: props.data.mdx.frontmatter.images[4].publicURL,
    rightImage: props.data.mdx.frontmatter.images[6].publicURL
  }), "\n", React.createElement(_components.p, null, "You can read how to make this effect with luminosity masks in ", React.createElement(L, {
    t: "this article",
    h: "https://arcpanel.averin.photo/blog/2020-05-22-painterly-soft-shadows-with-luminosity-masks/"
  }), "."), "\n", React.createElement(_components.h2, {
    id: "color-grade-in-adobe-photoshop-like-in-davinci-resolve",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#color-grade-in-adobe-photoshop-like-in-davinci-resolve",
    "aria-label": "color grade in adobe photoshop like in davinci resolve permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "Color Grade in Adobe Photoshop like in Davinci Resolve"), "\n", React.createElement(_components.p, null, "Color grading is a big topic in video production. It's kind of the same as post processing in photography, but for video. It helps convey the mood of videographer and make sure colors are correct in the resulting image."), "\n", React.createElement(_components.p, null, "Davinci Resolve is de-factor standard for video grading, but you can surely get very close to what Resolve does in Adobe Photoshop with luminosity masks."), "\n", React.createElement(ReactCompareImage, {
    leftImage: props.data.mdx.frontmatter.images[4].publicURL,
    rightImage: props.data.mdx.frontmatter.images[7].publicURL
  }), "\n", React.createElement(_components.p, null, "You can find exact steps how to make it work in ", React.createElement(L, {
    t: "this article",
    h: "https://arcpanel.averin.photo/blog/2020-05-22-color-grade-in-photoshop-like-in-davinci-resolve/"
  }), "."), "\n", React.createElement(_components.h2, {
    id: "summary",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#summary",
    "aria-label": "summary permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "Summary"), "\n", React.createElement(_components.p, null, "The amount of things you can do with luminosity masks is enormous. I came up with some of the techniques in this article myself to show you the example how you can experiment with masks youself."), "\n", React.createElement(_components.p, null, "Do you know any other nice tricks with masks? I will gladly accept guest-posts in the Luminosity Masking blgo if you are interested."));
}
function MDXContent(props = {}) {
  const {wrapper: MDXLayout} = Object.assign({}, _provideComponents(), props.components);
  return MDXLayout ? React.createElement(MDXLayout, props, React.createElement(_createMdxContent, props)) : _createMdxContent(props);
}
export default MDXContent;
